<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('menu.registrant.list')"
    class="px-5 py-3"
  >
    <v-simple-table v-if="items && items.length > 0">
      <thead>
        <tr>
          <th class="primary--text">No.</th>
          <th class="primary--text">
            {{ $t('agentRegistration.name') }}
          </th>
          <th class="primary--text">
            {{ $t('agentRegistration.phone') }}
          </th>
          <th class="primary--text">
            {{ $t('agentRegistration.email') }}
          </th>
          <th class="primary--text">
            {{ $t('general.status') }}
          </th>
          <th class="text-center primary--text">
            {{ $t('general.actions') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="item.registrant_user_uuid">
          <td>{{ index + 1 }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.phone_number }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.status }}</td>
          <td class="td--fit-content">
            <base-registrant-action-button
              :permissions="permissions"
              :allowedActions="item.allowed_actions"
              :basePath="basePath"
              :id="item.referral_registrant_id"
              @acceptAction="acceptAction"
              @rejectAction="rejectAction"
              @claimAction="claimAction"
            ></base-registrant-action-button>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <!--    <pagination-->
    <!--      :push-state="false"-->
    <!--      :meta="meta"-->
    <!--      @changePage="changePage"-->
    <!--      :maxVisibleButtons="5"-->
    <!--      v-if="meta"-->
    <!--    />-->
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
// const Pagination = () => import('@/components/utils/fractal-pagination');

export default {
  mixins: [HelperMixin],
  props: ['items', 'meta', 'permissions'],
  components: {
    // Pagination,
  },
  computed: {
    ...mapState({
      basePath: (state) => state.referralCode.basePath,
    }),
  },
  methods: {
    // changePage(page) {
    //   this.$emit('changePage', page);
    // },
    // changeFilter(val) {
    //   this.$emit('changeFilter', val);
    // },
    async acceptAction(id) {
      console.log('ACCEPT REFERRAL CODE ID: ', id);
      try {
        await this.$store.dispatch('referralCode/accept', id);
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('registrant.accept.successMsg'),
          'success',
        );
        this.$emit('refreshAction');
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    async rejectAction(id) {
      console.log('REJECT REFERRAL CODE ID: ', id);
      try {
        await this.$store.dispatch('referralCode/reject', id);
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('registrant.reject.successMsg'),
          'success',
        );
        this.$emit('refreshAction');
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    async claimAction(id) {
      console.log('CLAIM REFERRAL CODE ID: ', id);
      try {
        await this.$store.dispatch('referralCode/claim', id);
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('registrant.claim.successMsg'),
          'success',
        );
        this.$emit('refreshAction');
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>
